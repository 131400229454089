import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import {Component, Prop} from "vue-property-decorator";

@Component({
    name: 'GtrSurveysFinishView'
})
export default class GtrSurveysFinishView extends GtrSuper {

    @Prop()
    design: any

    @Prop()
    allContent: any

    data() {
        return {
            title: '',
            currentLanguage: 'en',
        }
    }

    mounted() {
        const session_uuid = this.$route.params.session_uuid
        if (Object.keys(this.$store.state.surveys.surveys).length > 0) {
             const keys = Object.keys(this.$store.state.surveys.surveys)
             let i = 0
             while (i < keys.length) {
                 if (keys[i] === session_uuid) {
                     if (session_uuid === 'general') {
                         this.$data.title = 'General Conference Feedback'
                     } else if (session_uuid === 'keynote') {
                         this.$data.title = 'Keynote Session Feedback'
                     }
                 } else if (keys[i] === 'sessions') {
                     this.$store.state.surveys.surveys[keys[i]].map((session: any) => {
                         if (session.session.uuid === session_uuid) {
                             this.$data.title = session.session.name
                         }
                     })
                 }
                 i++
             }
        }
    }

    get primaryButtonColor (): string {
        return this.design?.default_primary_button_color || 'var(--teal-300)'
    }

    get confirmationButtonVerbiage (): string {
        return this.allContent?.data?.evals_settings?.survey_confirmation_button_verbiage[this.$data.currentLanguage] ?? 'Go To Surveys List'
    }

    get confirmationPageVerbiage (): string {
        return this.allContent?.data?.evals_settings?.survey_confirmation_verbiage[this.$data.currentLanguage] ?? 'The survey was completed successfully.'
    }

}
